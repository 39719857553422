const msgs = {
  _: require<any>('./common.yml'),
  common: require<any>('./common.yml'),
  customer: require<any>('./customer.yml'),
  invoice: require<any>('./invoice.yml'),
  messages: require<any>('./messages.yml'),
  navigation: require<any>('./navigation.yml'),
  dashboard: require<any>('./dashboard.yml'),
  archive: require<any>('./archive.yml'),
  profile: require<any>('./profile.yml'),
  email: require<any>('./email.yml'),
  prelogin: require<any>('./prelogin.yml'),
  analytics: require<any>('./analytics.yml'),
  settings: require<any>('./settings.yml'),
  external: require<any>('./external.yml'),
};

export default msgs;
